// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-singles-cases-index-js": () => import("./../../../src/singles/Cases/index.js" /* webpackChunkName: "component---src-singles-cases-index-js" */),
  "component---src-singles-services-v-23-index-js": () => import("./../../../src/singles/ServicesV23/index.js" /* webpackChunkName: "component---src-singles-services-v-23-index-js" */),
  "component---src-templates-404-page-js": () => import("./../../../src/templates/404/Page.js" /* webpackChunkName: "component---src-templates-404-page-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/About/Page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-cases-page-js": () => import("./../../../src/templates/Cases/Page.js" /* webpackChunkName: "component---src-templates-cases-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/Contact/Page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-design-page-js": () => import("./../../../src/templates/Design/Page.js" /* webpackChunkName: "component---src-templates-design-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/Home/Page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-privacy-policy-page-js": () => import("./../../../src/templates/PrivacyPolicy/Page.js" /* webpackChunkName: "component---src-templates-privacy-policy-page-js" */),
  "component---src-templates-rich-content-digital-transformation-index-js": () => import("./../../../src/templates/RichContent/DigitalTransformation/index.js" /* webpackChunkName: "component---src-templates-rich-content-digital-transformation-index-js" */),
  "component---src-templates-services-page-js": () => import("./../../../src/templates/Services/Page.js" /* webpackChunkName: "component---src-templates-services-page-js" */)
}

