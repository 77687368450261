import 'assets/global/style.css';

// Reset
import 'normalize.css';

// Typography
import 'typeface-poppins';
import 'typeface-open-sans';

const nonRedirectablePathnameStarts = ['/blog', '/en/campaign'];

export const onInitialClientRender = () => {
  //const userLang = navigator.language.substr(0, 2);
  const localstoredLanguage = localStorage.getItem('locale');
  const locationPathname = window.location.pathname;
  const locationPathnameStartsWithEn = locationPathname.startsWith(
    '/en'
  );

  if (
    nonRedirectablePathnameStarts.some((prefix) => {
      return locationPathname.startsWith(prefix);
    })
  ) {
    return;
  }

  if (localstoredLanguage === 'pt' && locationPathnameStartsWithEn) {
    window.location.pathname = locationPathname.slice(3);
    return;
  }

  if (
    localstoredLanguage &&
    localstoredLanguage !== 'pt' &&
    !locationPathnameStartsWithEn
  ) {
    window.location.pathname = `/en${locationPathname}`;
    return;
  }

  // if (userLang !== 'pt' && !locationPathnameStartsWithEn) {
  //   window.location.pathname = `/en${locationPathname}`;
  //   return;
  // }
};
